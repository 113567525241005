<template>
	<div class="CommodityShopping">
		<w-navTab titleText="订单支付"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="CommodityShopping-body">
			<div class="container-goods">
				<div class="container-goods-item" v-for="item in products">
					<div class="container-goods-picture">
						<img :src="item.thumb" alt="">
					</div>
					<div class="container-goods-box">
						<div class="container-goods-box-title">
							{{item.title}}
						</div>
						<div class="container-goods-box-bottom">
							<div class="container-goods-box-bottom-price">
								<span>￥</span>{{item.price}}
							</div>
							<div class="container-goods-box-bottom-number">
								数量：{{item.num}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 地址 -->
			<div class="container">
				<div class="container-head" @click="show = !show">
					<div class="container-head-left">发货方式：</div>
					<div class="container-head-right">{{showText}}<van-icon name="arrow" color="#3377FF" /></div>
				</div>
				<div class="container-head" v-if="active == 2">
					<div class="container-head-left">自提地址：</div>
					<div class="container-bottom-text">{{merchant.address}}</div>
				</div>
				<template>
					<div class="container-bottom-text" v-if="detail == ''"
						@click="$router.push({ name: 'CommodityAddress' })">点击前往添加地址</div>
					<div class="container-bottom-text" v-if="detail != ''">
						{{detail.province}}-{{detail.city}}-{{detail.area}}
					</div>
					<div class="container-body">
						<div class="container-body-left">{{detail.detail}}</div>
						<div class="container-body-right" @click="$router.push({ name: 'CommodityAddress' })">
							切换地址<van-icon name="arrow" color="#aaa" /></div>
					</div>
					<div class="container-bottom-text">{{detail.name}} {{detail.telephone}}</div>
					<div class="container-footer-bottom">
						<img src="../../assets/img/icon500.png" alt="">
					</div>
				</template>
			</div>
			<div class="container-advert" v-if="type == 2">
				<div class="advert-title">注意事项</div>
				<div class="advert-text">
					1、虚拟物品有其特殊性与可复制性，一经售出概不支持退款，请知悉！
				</div>
			</div>
			<div class="container-goods-bottom">
				<div class="container-goods-bottom-title">
					<div class="container-goods-bottom-title-left">积分抵扣</div>
					<div class="container-goods-bottom-title-right">
						消耗{{sum.total_integral}}爱心积分抵扣<span>{{sum.total_discount}}</span>
					</div>
				</div>
				<div class="container-goods-bottom-text">
					您共有爱心积分：<span>{{sum.user_integral}}分</span>
				</div>
			</div>
			<div class="container-bottom">
				<div class="container-bottom-title">备注</div>
				<div class="container-bottom-textarea">
					<textarea v-model="content" placeholder="请输入下单备注"></textarea>
					<div class="message-text-number">
						<div>({{ content.length }}/300)</div>
					</div>
				</div>
			</div>
			<div class="container-footer">
				<div class="container-footer-right-text">
					<div class="footer-right-text-title">
						<span>￥</span>{{sum.total_pay_amount}}
					</div>
					<div class="footer-right-text-rebate">
						<span>-{{sum.total_integral}}</span>爱心积分
					</div>
				</div>
				<div class="container-footer-right-btn" @click="onClickPay">支付</div>
			</div>
			<van-action-sheet cancel-text="取消" v-model="show" :actions="actions" @select="onSelect" />
		</div>

	</div>
</template>

<script>
	import Event from '@/store/event';
	import Vant from '@/vendor/vant.js';
	import Commodity from '@/api/commodity';
	import LoadingPage from '@/components/LoadingPage';
	export default {
		name: 'CommodityShopping',
		data() {
			return {
				detail: '',
				products: '',
				sum: '',
				cartId: '',
				piace: '',
				type: '',
				merchant: '',
				show: false,
				showText: '快递发货',
				active: 1,
				actions: [{
					id: 1,
					name: '快递发货'
				}, {
					id: 2,
					name: '自提地址'
				}],
				loadingPage: true,
				content: ''
			};
		},
		watch: {
			content(newVal, oldVal) {
				if (newVal.length > 300) {
					this.content = this.content.substring(0, 300);
				}
			},
		},
		created() {
			this.cartId = this.$route.params.id
			this.$store.commit('keep/setKeep', 'CommodityShopping');
			this.getList()
			Event.$off(['onCommodityAddress']);

			// 监听选择地址
			Event.$on('onCommodityAddress', address => {
				this.detail = address
			});
		},
		methods: {
			onSelect(item) {
				this.active = item.id
				this.showText = item.name
				this.show = !this.show;
			},
			getList() {
				let params = {
					cart_ids: this.cartId,
				}
				Commodity.productCartDetail(params)
					.then(result => {
						if (result.data.address == null) {
							this.detail == ''
						} else {
							this.detail = result.data.address;
						}
						this.products = result.data.products;
						this.type = result.data.product_type
						this.merchant = result.data.merchant
						this.sum = result.data.sum;
						this.loadingPage = false
					}, error => {
						Vant.Toast(error.msg);
					});
			},
			onClickPay() {
				if (this.detail == "") {
					return Vant.Toast('请添加收货地址');
				}
				let params = {
					pick_up: this.active,
					cart_ids: this.cartId,
					address_id: this.detail.id,
					remark: this.content
				}
				Vant.Dialog.confirm({
						title: '支付提示',
						message: '您确认支付吗？',
					})
					.then(() => {
						Commodity.productCartOrder(params)
							.then(result => {
								this.piace = result.data;
								if (this.piace.pay_code == 1) {
									Vant.Toast.success('支付成功');
									setTimeout(() => {
										this.$router.replace({
											name: 'CommodityFinish',
											params: {
												data: result.data
											}
										});
									}, 500)
								} else {
									wx.chooseWXPay({
										appId: this.piace.appId,
										timestamp: this.piace.timeStamp,
										nonceStr: this.piace.nonceStr,
										package: this.piace.package,
										signType: this.piace.signType,
										paySign: this.piace.paySign,
										// 支付成功后的回调函数
										success: res => {
											if (res.errMsg === 'chooseWXPay:ok') {
												Vant.Toast.success('支付成功');
												setTimeout(() => {
													this.$router.replace({
														name: 'CommodityFinish',
														params: {
															data: result.data
														}
													});
												}, 500)
											}
										},
										// 支付取消回调函数
										cancel: res => {
											console.log('用户取消支付~')
										},
										// 支付失败回调函数
										fail: res => {
											console.log('支付失败~')
										}
									});
								}
							}, error => {
								Vant.Toast(error.msg);
							});
					})
			}
		},
		components: {
			LoadingPage
		}
	};
</script>

<style lang="less" scoped>
	.CommodityShopping {
		width: 100%;
		min-height: 100vh;

		.CommodityShopping-body {
			padding: 10px;
			padding-top: 15px;
			background: #f2f2f2;
			padding-bottom: 75px;
			box-sizing: border-box;

			.container {
				margin-top: 15px;
				background: #fff;
				border-radius: 6px;
				padding: 12px;
				padding-bottom: 0;

				.container-head {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 8px;

					.container-head-left {
						font-weight: bold;
						font-size: 14px;
						line-height: 17px;
						color: #333333;
						min-width: 75px;
					}

					.container-head-right {
						font-size: 14px;
						line-height: 17px;
						color: #3377FF;
					}
				}

				.container-body {
					display: flex;
					justify-content: space-between;
					align-items: center;

					.container-body-left {
						font-size: 18px;
						line-height: 26px;
						color: #151C39;
					}

					.container-body-right {
						font-size: 14px;
						line-height: 26px;
						color: #666;
					}
				}

				.container-bottom-text {
					font-size: 14px;
					line-height: 28px;
					color: #666;
				}

				.container-footer-bottom {
					height: 100%;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

			}

			.container-goods {
				padding: 0 12px;
				background: #FFF;
				border-radius: 8px;

				.container-goods-item:last-child {
					border-bottom: 0;
				}

				.container-goods-item {
					padding: 12px 0;
					border-bottom: 1px solid #F2F2F2;
					display: flex;

					.container-goods-picture {
						width: 80px;
						height: 80px;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
							border-radius: 6px;
						}
					}

					.container-goods-box {
						flex: 1;
						padding-left: 10px;

						.container-goods-box-title {
							font-size: 16px;
							line-height: 22px;
							color: #151C39;
							height: 44px;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
						}

						.container-goods-box-bottom {
							display: flex;
							justify-content: space-between;
							align-items: center;
							margin-top: 14px;

							.container-goods-box-bottom-price {
								font-size: 22px;
								line-height: 22px;
								color: #F9615D;

								span {
									font-size: 16px;
									line-height: 16px;
								}
							}

							.container-goods-box-bottom-number {
								font-size: 16px;
								line-height: 22px;
								color: #777;
							}
						}
					}

				}

			}

			.container-goods-bottom {
				padding: 12px 12px 10px;
				background: #FFF;
				border-radius: 6px;
				box-sizing: border-box;
				margin-top: 15px;

				.container-goods-bottom-title {
					display: flex;
					justify-content: space-between;
					align-items: center;

					.container-goods-bottom-title-left {
						font-weight: bold;
						font-size: 14px;
						line-height: 17px;
						color: #333;
					}

					.container-goods-bottom-title-right {
						font-size: 14px;
						line-height: 17px;
						color: #666;
						display: flex;

						span {
							display: block;
							display: flex;
							font-size: 14px;
							line-height: 17px;
							color: #F43E3D;

							&:before {
								content: '￥';
								font-size: 10px;
								line-height: 17px;
							}
						}
					}
				}

				.container-goods-bottom-text {
					font-size: 13px;
					line-height: 20px;
					color: #777;
					margin-top: 12px;
					text-align: right;

					span {
						color: #3377FF;
					}
				}

			}

			.container-bottom {
				background: #FFF;
				border-radius: 8px;
				padding: 12px;
				margin-top: 15px;

				.container-bottom-title {
					font-size: 14px;
					line-height: 21px;
					color: #222;
					margin-bottom: 5px;
				}

				.container-bottom-textarea {

					textarea {
						height: 70px;
						border: 0;
					}

					.message-text-number {
						text-align: right;
						font-size: 14px;
						line-height: 26px;
						color: #777;
					}
				}
			}

			.container-advert {
				padding: 12px;
				background: rgba(255, 178, 63, 0.5);
				border-radius: 6px;
				box-sizing: border-box;
				margin-top: 15px;

				.advert-title {
					font-weight: bold;
					font-size: 14px;
					line-height: 17px;
					color: #151C39;
				}

				.advert-text {
					font-size: 14px;
					line-height: 18px;
					color: #222;
					margin-top: 8px;
				}
			}

			.container-footer {
				width: 100%;
				height: 64px;
				position: fixed;
				left: 0;
				bottom: 0;
				display: flex;
				justify-content: space-between;
				align-items: center;
				background: #fff;
				box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);

				.container-footer-right-text {
					padding-left: 10px;
					display: flex;
					align-items: center;

					.footer-right-text-title {
						font-size: 28px;
						color: #f9615d;
						text-align: right;

						span {
							font-size: 16px;
						}
					}

					.footer-right-text-rebate {
						display: flex;
						align-items: center;
						font-size: 11px;
						padding-left: 8px;
						color: #666;
						line-height: 12px;

						span {
							display: block;
							font-size: 11px;
							color: #f43e3d;
							line-height: 12px;
						}
					}
				}

				.container-footer-right-btn {
					font-size: 16px;
					line-height: 22px;
					color: #fff;
					padding: 9px 34px;
					background: #3377ff;
					border-radius: 100px;
					margin: 0 10px;
				}
			}
		}

	}
</style>